p{
    letter-spacing: 2px;
    font-size: 12px;
    line-height: 14px;
}

span{
    letter-spacing: 2px;

}

.hovered-icon {
  transition: fill 0.3s ease;
}

button:not(:disabled):hover .hovered-icon {
  fill: black;
}

.car-icon {
  color: white; 
  transition: color 0.3s ease;
}

button:not(:disabled):hover .car-icon {
  color: black; 
}

.attach-icon {
  color: black; 
  transition: color 0.3s ease;
}

button:not(:disabled):hover .attach-icon {
  color: white; 
}
